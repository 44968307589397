<template>
  <div>
    <EventTournamentListModal />
    <TournamentFormatModal />
    <EditTournamentModal :eventId="eventId"
      :ageOptions="ageOptions" 
      :ratingOptions="ratingOptions"
      :association_id="association_id"/>
    <ManageTournamentSeedars :tournament_id="tournament_id" :association_id="association_id"/>

    <div class="sec-top d-flex justify-content-between" v-if="tournamentData && showLoader == 0 ">
      <div class="left-part">
        <div class="score-game-name" @click="openTournametListModal(tournamentData)">
          <span>Switch Division</span>
          <h6 class="text-capitalize">{{ tournamentData?.tournamentName }}</h6>
        </div>

          <div class="event-list-details mt-0 event-info-wrap">
        <div class="event-date mt-0">
          <img src="../../assets/images/calendar-event.png" />
          <div class="content" v-if="startDateYear === endDateYear">
            {{ formatDate(tournamentData.exactStartDate) }} - {{ formatDate(tournamentData.exactEndDate) }}, {{ startDateYear }}
          </div>
          <div class="content" v-else>
            {{ formatDate(tournamentData.exactStartDate) }}, {{ startDateYear }} - {{ formatDate(tournamentData.exactEndDate) }}, {{ endDateYear }}
          </div>

        </div>
      </div>
    </div>
    <div class="right-part asso-right-part">
      <router-link v-if="tournamentData.event" :to="{ name: 'AssociationSingleEventDetail', params: { id: tournamentData.event.guid } }">
          <h4 class="text-capitalize text-md-right cursor-pointer text-primary">
            {{ tournamentData.event.eventName }}
          </h4> 
      </router-link>   
      <h4 class="text-capitalize cursor-pointer text-md-right text-primary" v-else>
        N/A
      </h4>
      
      <div class="format-button d-block d-md-flex align-items-center mt-2">
        <div v-if="tournamentData.format !== '' && tournamentData.format !== null">
          <button class="btn view-format" @click="TournamentFormatModal(tournamentData)"><img src="../../assets/eye.png">View Division Format</button>
        </div>
        <div class="format-button mt-3 mt-md-0 d-flex align-items-center justify-content-end">
        <div class="edit-event-div mr-0" @click="callTourModal()" v-if="currentIsAdmin == '1'">
            <button class="btn view-format"><img src="../../assets/math.png" alt="">Seed Criteria</button>
          </div>
        <div class="opt edit-btn my-0" v-if="currentIsAdmin == '1'">
          <img
            src="../../assets/images/ellipsis-h.png"
            alt=""
            @click="openGameOption"
            v-click-outside="onClickOutside"
          />
          <div class="sub-setting-options edit-event-options" v-if="gameOption">
            <ul class="d-block pb-0 text-left">
              <!-- <li class=""> 
                <router-link class="score-redirect" :to="{ name: 'TournamentScores', params: { guid: tournamentData.guid } }">
                    Scoring
                </router-link>
              </li> -->
              
               <li @click="callModal()" class="cursor-pointer">Edit Division</li>
            </ul>
        </div>
        </div>
        </div>
      </div>

    </div>

    </div>

    <div class="products-list-shim p-0" v-if="showLoader == 1">
        <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
        </content-placeholders>
    </div>
</div>
</template>

<script>
import axios from "axios";
import EventTournamentListModal from '../Tournaments/Modals/EventTournamentListModal';
import TournamentFormatModal from '../Tournaments/Modals/TournamentFormatModal.vue';
import EditTournamentModal from '../Association/Modals/EditTournament.vue';
import ManageTournamentSeedars from "./Modals/TournamentSeedarModal.vue";

export default {
  components: {  EventTournamentListModal, TournamentFormatModal,ManageTournamentSeedars, EditTournamentModal },
  name: "TournamentTop",
  data() {
    return {
      gameOption: false,
      eventId: this.tournamentData.event_id,
      ageOptions: [],
      ratingOptions: [],
    };
  },
  props: {
    tournamentData: {
      typeof: Object,
    },
    showLoader: {
      typeof: Number,
      default: 1,
    },
    tournament_id: {
      typeof: Number,
    },
    association_id: {
      type: Number,
    },
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  computed: {
    startDateYear() {
      return new Date(this.tournamentData.startDate).getFullYear();
    },
    endDateYear() {
      return new Date(this.tournamentData.endDate).getFullYear();
    }
  },
  methods: {
    TournamentFormatModal(tournamentData) {
      this.$root.$emit("openTournamentFormatModal", tournamentData);
    },
    openTournametListModal(tournamentData) {
      this.$root.$emit("openEventTournamentListModal", tournamentData);
    },
    callTourModal(){
      console.log("hekek")
      this.$root.$emit("openManageTourSeedarsModal");
    },
    // callModal() {
    //   this.$root.$emit("openTournamentEditGameModal");
    // },
    async deleteGame() {
      this.$confirm(
        "About to remove this game. Continue ?",
        "Remove Game."
      )
    },
    openGameOption() {
      this.gameOption = !this.gameOption;
    },
    onClickOutside() {
      this.gameOption = false;
    },
    callModal() {
      this.$root.$emit("openTournamentEditModal", this.tournamentData);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      const weekday = date.toLocaleString('default', { weekday: 'short' }); // Get the short weekday

      return `${weekday}, ${month} ${day}`; // Return day of the week, month, and day
    },

    tournamentScoreCall() {
      const urlToOpen = `/tournament/game-score`;
      window.location.href = urlToOpen;
    },
    async getTournamentDetail() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/getSingleTournament`,
          {tournament_guid: tournamentGuid}
        );

        if (response.data.statusCode === 200) {
            this.tournamentData = response.data.data;
            // this.$root.$emit("tournmentSingleGameForTab", this.tournamentData )
            // this.$root.$emit("tournmentSingleGameForGame", this.tournamentData )
            this.showLoader = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
  },
  getAgeGroup() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getAllRating() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllRatings`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ratingOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
},
  mounted() {
    this.getAgeGroup();
    this.getAllRating();
    this.$root.$on("fetchTournaments", () => {
      this.getTournamentDetail();
    });
    this.$root.$on("fetchTournaments1", () => {
      this.getTournamentDetail();
    });
  },
};
</script>
<style scoped>
.format-button {
    display: flex;
    gap: 10px;
      /* align-items: center; */
    }
    
    .view-format {
      gap: 10px;
      display: flex;
      background-color: #F0F0F0;
      color: #254c72;border-radius: 5px;
    font-size: 14px;
    font-weight: 600
}
.create-event {
  background-color: #F0F0F0;
}
.edit-event-div{
  margin-right: 4px;
}

</style>
