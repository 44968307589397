<template>
  <div>
    <TournamentAddGameModal :tournamentData="tournamentData" :association_id="association_id" />
    <TournamentEditGameModal :tournamentData="tournamentData" :association_id="association_id"/>
    <div class="d-flex justify-content-end" v-if="currentIsAdmin == '1'">
      <button
        class="btn btn-primary"
        @click="callModal()"
        
      >
        New Game
      </button>
    </div>
    <div class="overflow-auto" v-if="tournamentGame.length > 0">
      <b-table
        :items="[]"
        :fields="fields"
        responsive
        no-sort-reset
        class="mb-0 tournament-game-table"
      >
      </b-table>

      <div v-for="(game, start_date) in groupedTeams" :key="start_date">
        <p class="py-2">
          <span class="bold-make">{{ formatDate(start_date) }} </span>
        </p>
        <b-row class="no-gutters" v-if="game.length > 0">
          <div class="col-12">
            <div class="event-card">
              <div class="table-responsive mb-0" style="overflow: visible">
                <b-table
                  :items="game"
                  :fields="fields"
                  hover
                  responsive
                  thead-class="d-none"
                  class="mb-0 tournament-game-table"
                >
                  <template v-slot:cell(team_one.team1Runs)="data">
                    <div class="group-table-name" v-if="data.item.team_one">
                      <p
                        :class="{
                          'text-primary':
                            data.item.team1Runs > data.item.team2Runs,
                          'text-danger':
                            data.item.team1Runs < data.item.team2Runs,
                        }"
                      >
                        {{
                          data.item.team1Runs === 0 && data.item.team2Runs === 0
                            ? "- "
                            : data.item.status != 1 && data.item.team1Runs !== data.item.team2Runs
                            ? (data.item.team1Runs > data.item.team2Runs
                                ? "W - "
                                : "L - ") + data.item.team1Runs
                            : data.item.team1Runs
                        }}
                      </p>
                    </div>
                    <div class="group-table-name" v-else>
                      <p>-</p>
                    </div>
                  </template>

                  <template  v-slot:cell(start_time)="data">
                    <div>
                      {{ data.item.start_time }}
                    </div>
                  </template>
                  
                  <template v-slot:cell(field.field_name)="data">
                      <div v-if="data.item.field && data.item.field.field_name">
                          {{ data.item.field.field_name }}
                      </div>
                      <div v-else>
                          {{ "-" }}
                      </div>
                  </template>
                  <template v-slot:cell(team_one.team_sr_no)="data">
                    <div class="d-flex align-items-center">
                        <div class="user-img" v-if="data.item.team_one && data.item.team_one.team_data.team_name">
                            <div class="users-notification" v-if="imgLoader == 1">
                              <!-- <div class="lds-dual-ring-refresh"></div> -->
                              <img src="../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad"
                              v-if="data.item.team_one && data.item.team_one.team_data.team_avatar"
                              :src="url + data.item.team_one.team_data.team_avatar"
                              alt=""
                              style="border: 2px solid white"
                            />
                            <img
                              @load="onImageLoad"
                              v-else
                              alt=""
                              src="../../assets/team-avatar.png"
                            />
                          </div>
                          <div v-if="data.item.team_one && data.item.team_one.team_data.team_name" class="pl-2">
                            {{ `#${data.item.team_one.team_sr_no}: ${data.item.team_one.team_data.team_name}` }}
                          </div>
                          <div v-else>
                            TBD
                        </div>
                      </div>
                  </template>

                  <template v-slot:cell(team_two.team_sr_no)="data">
                    <div class="d-flex align-items-center">
                        <div class="user-img" v-if="data.item.team_two && data.item.team_two.team_data.team_name">
                            <div class="users-notification" v-if="imgLoader == 1">
                              <!-- <div class="lds-dual-ring-refresh"></div> -->
                              <img src="../../assets/loader.gif" alt="" />
                            </div>
                            <img @load="onImageLoad"
                              v-if="data.item.team_two && data.item.team_two.team_data.team_avatar"
                              :src="url + data.item.team_two.team_data.team_avatar"
                              alt=""
                              style="border: 2px solid white"
                            />
                            <img
                              @load="onImageLoad"
                              v-else
                              alt=""
                              src="../../assets/team-avatar.png"
                            />
                          </div>
                          <div v-if="data.item.team_two && data.item.team_two.team_data.team_name" class="pl-2">
                            {{ `#${data.item.team_two.team_sr_no}: ${data.item.team_two.team_data.team_name}` }}
                          </div>
                          <div v-else>
                            TBD
                        </div>
                      </div>
                  </template>

                 

                  <template v-slot:cell(team_two.team2Runs)="data">
                    <div class="group-table-name" v-if="data.item.team_two">
                      <p
                        :class="{
                          'text-primary':
                            data.item.team2Runs > data.item.team1Runs,
                          'text-danger':
                            data.item.team2Runs < data.item.team1Runs,
                        }"
                      >
                        {{
                          data.item.team2Runs === 0 && data.item.team1Runs === 0
                            ? "- "
                            : data.item.status != 1
                             && data.item.team1Runs !== data.item.team2Runs
                            ? (data.item.team2Runs > data.item.team1Runs
                              ? "W - " : "L - ") + data.item.team2Runs
                            : data.item.team2Runs
                        }}
                      </p>
                    </div>
                    <div class="group-table-name" v-else>
                      <p>-</p>
                    </div>
                  </template>

                  <template v-slot:cell(game_name)="data">
                    <div class="d-flex">
                      <div class="tournament-delayed-tag"
                        v-if="data.item.game_delayed == 1" >
                        <p class="">Delayed</p>
                      </div>
                      <div class="tournament-live-tag"
                      v-else-if="data.item.game_live == 1">
                        <p class="">Live</p>
                      </div>

                      <div class="group-table-name">
                        <p>{{ data.item.game_name }}</p>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(Action)="data">
                    <div
                      @click="handleActionClick(data.item)"
                      class="d-flex justify-content-end"
                    >
                      <TournamentEllipsis
                        v-if="
                          currentIsAdmin == '1' || data.item.umpire_id == UserId
                        "
                        :clickedGame="clickedGame"
                        :currentIsAdmin="currentIsAdmin"
                        :currentIsUmpire="currentIsUmpire"
                        :association_id="association_id"
                      />
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </div>
    <div
      class="no-products-in-shop"
      v-if="
        (!tournamentGame || tournamentGame.length === 0) && showLoader === 0
      "
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No division game found.</p>
      <p class="info">
        There are no division game to display.
      </p>
    </div>

    <div class="products-list-shim p-0 mt-3" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import TournamentAddGameModal from "./Modals/TournamentAddGameModal.vue";
import TournamentEditGameModal from "../Tournaments/Modals/TournamentEditGameModal.vue";
import TournamentEllipsis from "./Modals/TournamentTeamActionModal.vue";

export default {
  name: "TournamentGames",
  components: {
    TournamentAddGameModal,
    TournamentEditGameModal,
    TournamentEllipsis,
  },
  data() {
    return {
      url: this.$groupAvatarStorageURL,
      imgLoader: 1,
      clickedGame: [],
      showLoader: 1,
      UserId: localStorage.getItem("userId"),
      tournamentGame: [],
      fields: [
        {
          key: "game_name",
          label: "Game",
          class: "columnheader pl-0 text-start",
          thStyle: { minWidth: "200px", maxWidth: "200px" },
        },
        {
          key: "start_time",
          label: "Time",
          class: "columnheader text-start",
          thStyle: { minWidth: "80px", maxWidth: "80px" },
        },
        {
          key: "team_one.team1Runs",
          label: "Runs",
          class: "columnheader text-start",
          thStyle: { minWidth: "80px", maxWidth: "80px" },
        },
        {
          key: "team_one.team_sr_no",
          label: "Team",
          class: "columnheader text-start",
          thStyle: { minWidth: "300px", maxWidth: "300px" },
          
        },
        {
          key: "field.field_name",
          label: "Field",
          class: "columnheader text-start",
          thStyle: { minWidth: "80px", maxWidth: "80px" },
        },
        {
          key: "team_two.team2Runs",
          label: "Runs",
          class: "columnheader text-start",
          thStyle: { minWidth: "80px", maxWidth: "80px" },
        },
        {
          key: "team_two.team_sr_no",
          label: "Team",
          class: "columnheader text-start",
          thStyle: { minWidth: "300px", maxWidth: "300px" },
          formatter: (value, key, item) => {
            if (!value || !item.team_two?.team_data?.team_name) {
              return "TBD";
            }
            const teamNumber = `#${value}`;
            const teamName = item.team_two?.team_data?.team_name;
            return `${teamNumber}: ${teamName}`;
          },
        },
        { key: "Action", label: "", class: "columnheader" },
      ],
    };
  },
  props: {
    tournamentData: {
      typeof: Object,
    },
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    association_id: {
      type: Number,
    },
    associationId: {
      type: Number,
    },
  },
  computed: {
    groupedTeams() {
      const grouped = {};
      this.tournamentGame.forEach((game) => {
        const startDate = game.start_date;
        if (!grouped[startDate]) {
          grouped[startDate] = [];
        }
        grouped[startDate].push(game);
      });

      // Sort the games within each date group by start_time
      Object.keys(grouped).forEach((key) => {
        grouped[key].sort((a, b) => {
          const dateA = new Date(`2000-01-01 ${a.start_time}`);
          const dateB = new Date(`2000-01-01 ${b.start_time}`);
          return dateA - dateB;
        });
      });

      // Sort the date groups in ascending order
      const sortedGrouped = {};
      Object.keys(grouped)
        .sort((a, b) => new Date(a) - new Date(b))
        .forEach((key) => {
          sortedGrouped[key] = grouped[key];
        });
      return sortedGrouped;
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    handleActionClick(data) {
      
      this.clickedGame = data;
    },
    callModal() {
      this.$root.$emit("openTournamentAddGameModal");
    },
    formatDate(dateString) {
      const dateObj = moment(dateString);
      const day = dateObj.format('dddd'); // Full weekday name
      const month = dateObj.format('MMMM'); // Full month name
      const numericDate = dateObj.format('DD'); // Day of the month
      const year = dateObj.format('YYYY'); // Four-digit year

      return `${day}, ${month}, ${numericDate}, ${year}`;
    },
    async getTournamentGame() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/getTournamentGames`,
          {tournament_guid: tournamentGuid});

        if (response.data.statusCode === 200) {
          this.tournamentGame = response.data.data;
          this.showLoader = 0;
        } else if (response.data.statusCode === 404) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              this.showLoader = 0;
            } 
        //  else if (response.data.statusCode === 404) {
        //   this.$router.push({ name: "PageNotFound" });
        //   this.showLoader = 0;
        // }
         else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
    },
  },
  mounted() {   
    this.getTournamentGame();
    this.$root.$on("RefreshTournamentGames", () => {
      this.tournamentGame = [];
      this.getTournamentGame();
    });
    this.$root.$on("showTournamentForSwitch", () => {
      this.tournamentGame = [];
      this.getTournamentGame();
    });
  },
};
</script>
<style scoped>

</style>
