<template>
  <div>
    <AddTournament  :eventExactStartDate="eventExactStartDate"
    :eventExactEndDate="eventExactEndDate" :eventId="eventId" :currentIsAdmin="currentIsAdmin" :association_id="association_id"
      :ageOptions="ageOptions" :ratingOptions="ratingOptions" />
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" @click="openModal()" v-if="currentIsAdmin == '1'">
        Add Division
      </button>
    </div>
    <b-row class="no-gutters" v-if="tournamentsData.length > 0">
      <div class="col-12 products-column">
        <div class="event-card">
          <div class="table-responsive mb-0">
              <b-table :items="tournamentsData" :fields="fields" hover responsive
                class="mt-3 b-datatable cursor-pointer">
                <template v-slot:cell(tournamentName)="data" @row-clicked="redirectToTournament">
                  <router-link :to="{ name: 'Tournament', params: { id: data.item.guid } }" class="stats-link">
                    {{ data.item.tournamentName }}
                  </router-link>
                </template>

                <template v-slot:cell(Date)="data">
                  {{ data.item.date }}
                </template>
                <template v-slot:cell(teamCounts)="data">
                  {{ data.item.teamCounts }} Teams
                </template>
                <template v-if="currentIsAdmin == '1'" v-slot:cell(Action)="data">
                  <div @click="handleActionClick(data.item)" class="d-flex justify-content-end">
                    <EditTournamentEllipsis :association_id="association_id" :tournament="data.item" :eventId="eventId" :eventExactStartDate="eventExactStartDate"
                    :eventExactEndDate="eventExactEndDate" 
                      :currentIsAdmin="currentIsAdmin" :ageOptions="ageOptions" :ratingOptions="ratingOptions" />
                  </div>
                </template>
              </b-table>

              <content-placeholders :rounded="true" v-if="tournamentsData.length > 0 && showLoaderTournament == 1">
                <content-placeholders-heading :img="false" />
              </content-placeholders>
           
          </div>
        </div>
      </div>
    </b-row>

    <div v-if="tournamentsData.length"
     v-observe-visibility="handleScrolledToButtom">
    </div>


    <div v-if="
        (!tournamentsData || tournamentsData.length === 0) && showLoaderTournament === 0"
         class="no-products-in-shop">
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No division found.</p>
      <p class="info">There are no division found to display.</p>
    </div>
    <div class="products-list-shim p-0 mt-3" v-if="tournamentsData.length === 0 && showLoaderTournament == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>
  
<script>
import EditTournamentEllipsis from "./EditTournamentEllipsis.vue";
import AddTournament from "./Modals/AddTournament.vue";
import axios from "axios";
export default {
  components: { AddTournament, EditTournamentEllipsis },
  name: "AssociationTournamentInfo",

  data() {
    return {
      fields: [
      { key: "tournamentName", label: "Divisions", sortable: true },
        {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            const startDate = new Date(item.exactStartDate);
            const endDate = new Date(item.exactEndDate);

            // Updated to include the day of the week
            const startDateString = startDate.toLocaleDateString("en-US", {
              weekday: "short", // This adds the day of the week
              month: "short",
              day: "numeric",
            });
            const endDateString = endDate.toLocaleDateString("en-US", {
              weekday: "short", // This adds the day of the week
              month: "short",
              day: "numeric",
            });
            const startYearString = startDate.getFullYear();
            const endYearString = endDate.getFullYear();

            if (startYearString === endYearString) {
              return `${startDateString} to ${endDateString}, ${startYearString}`;
            } else {
              return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
            }
          },
          sortBy: (item) => {
            // Custom sorting function based on startDateForField
            return new Date(item.startDateForField).getTime(); // Sort by timestamp
          },
        },

        { key: "teamCounts", label: "Teams", sortable: true },
        { key: "Action", label: "", class: "columnheader" },
      ],
      ageOptions: [],
      ratingOptions: [],
    };
  },
  props: {
    tournamentsData: {
      type: Array,
    },
    eventId: {
      // Define umpires prop to receive the umpire data
      type: Number,
    },
    showLoaderTournament: {
      type: Number,
    },
    divisionPage: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
    currentIsAdmin: {
      type: String,
    },
    divisionlastPage: {
      type: Number,
    },
    eventExactStartDate: {
      type: String,
    },
    eventExactEndDate: {
      type: String,
    },
  },
  methods: {
    redirectToTournament(item) {
      const tournamentId = item.guid;
      this.$router.push({ name: "Tournament", params: { id: tournamentId } });
    },
    openModal: function () {
      this.$root.$emit("openAddTournamentModal");
    },
    handleActionClick() {
      this.$root.$emit("openEditTournamentEllipsis");
    },
    handleScrolledToButtom(isVisible) {
      if (this.divisionPage >= this.divisionlastPage) {
        return;
      }
      if (isVisible) {
        this.$root.$emit("getDivisionPagination"); // Emit event to notify parent component
      }
    },
    getAgeGroup() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getAllRating() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllRatings`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ratingOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
  },
  mounted() {
    this.getAgeGroup();
    this.getAllRating();
    
  },
};
</script>
