<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#editHotel"
      ref="openModalEditHotel"
      @click="dataReset1()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="editHotel">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Edit Hotel</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeEditHotel"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <form @keyup.enter="editHotel(0)" @submit.prevent>
              <div class="profile-preview align-items-end">
                <div class="profile-photo-edit d-flex" @click="openinput()">
                  <img
                      v-if="
                        this.hotelAvatar.length == 0 &&
                          this.hotelFinalAvatar == ''
                      "
                      src="../../../assets/images/sponsor.png"
                      alt=""
                      class="default-image"
                    />
                    <img
                      v-else-if="this.hotelAvatar.length == 0"
                      :src="this.$eventHotelStorageURL + this.hotelFinalAvatar"
                      alt=""
                    />

                  <form>
                    <input
                      type="file"
                      id="vue-file-upload-input-hotel-photo-edit"
                      @change="onFileChange"
                      class="d-none"
                      accept="image/png, image/gif, image/jpeg"
                    />

                    <div id="preview" class="new-profile-photo">
                      <clipper-basic
                      v-if="this.hotelAvatar.length > 0"
                        :src="this.hotelrawData"
                        preview="preview"
                        :border="4"
                        :outline="0"
                        :corner="false"
                        :ratio="1"
                        :wrap-ratio="1"
                         :init-width="100"
                        :init-height="100"
                        ref="clipper"
                      ></clipper-basic>
                    </div>

                    <div class="profile-photo-edit-icon-wrapper">
                      <img src="../../../assets/images/edit.png" alt="" />
                    </div>
                  </form>
                </div>
                <div class="preview-image">
                  <div v-if="this.hotelAvatar.length > 0">
                    <clipper-preview name="preview"></clipper-preview>
                  </div>
                  <div class="no-image-wrapper" v-else>
                    <div slot="placeholder">No image</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Hotel Name</label>
                <input
                  id="name"
                  class="form-control"
                  v-model.trim="$v.details.name.$model"
                  spellcheck="false"
                  autocomplete="off"
                  :class="{
                    'is-invalid': validationStatus($v.details.name),
                  }"
                  trim
                  type="text"
                  required
                  v-on:keyup="errorNull"
                />
                <div v-if="!$v.details.name.required" class="invalid-tooltip">
                  Hotel name is required
                </div>
                <div v-if="!$v.details.name.maxLength" class="invalid-tooltip">
                  Maximum 18 characters allowed.
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-12 col-md-6 pr-0 pr-md-2">
                  <div class="form-group">
                    <label for="url">Website URL</label>
                    <input
                      type="text"
                      class="form-control"
                      id="url"
                      v-model="url"
                      @keyup="errorNull"
                      @input="change($event)"
                      @change="change($event)"
                      required
                    />

                    <div class="error-sponser" v-if="!isValid && url">
                      URL is Invalid
                    </div>
                    <div class="error-sponser" v-if="!url">
                      URL is Required
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="mob_phone">Contact Number</label>
                    <input
                      id="mob_phone"
                      class="form-control"
                      v-model.trim="$v.details.mob.$model"
                      spellcheck="false"
                      autocomplete="off"
                      :class="{
                        'is-invalid': validationStatus($v.details.mob),
                      }"
                      trim
                      required
                      type="text"
                      v-on:keyup="errorNull"
                    />
                    <div
                      v-if="!$v.details.mob.required"
                      class="invalid-tooltip"
                    >
                      Mobile number is required
                    </div>
                    <div
                      v-if="!$v.details.mob.minLength"
                      class="invalid-tooltip"
                    >
                      Minimum length should be 10
                    </div>
                    <div v-if="!$v.details.mob.integer" class="invalid-tooltip">
                      Mobile number should be a number
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <gmap-autocomplete
                      :value="location"
                      placeholder="Enter an address, zipcode, or location"
                      @place_changed="setPlace"
                      ref="autocompleteRef"
                    >
                    </gmap-autocomplete>
                  </div>
                </div>
                <div class="col-12">
                  <google-map
                    :center="marker.position"
                    :zoom="18"
                    map-style-id="roadmap"
                    :options="mapOptions"
                    style="width: 100vmin; height: 40vmin; margin-bottom: 10px"
                    ref="mapRef"
                    id="map"
                    @click="handleMapClick"
                  >
                    <google-maps-marker
                      :position="marker.position"
                      :clickable="true"
                      :draggable="true"
                      @drag="handleThrottledMarkerDrag"
                      @click="panToMarker"
                    ></google-maps-marker>
                  </google-map>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="street"
                      v-model="street"
                      @keyup="errorNull"
                      required
                      placeholder="Street Address"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-4 pr-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      v-model="city"
                      @keyup="errorNull"
                      required
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4 px-md-0">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="state"
                      v-model="state"
                      @keyup="errorNull"
                      required
                      placeholder="State"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4 pl-md-2">
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="zipCode"
                      v-model="zipCode"
                      @keyup="errorNull"
                      required
                      placeholder="ZipCode"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-between">
            <button
              type="button"
              class="btn btn-danger w-25"
              block
              @click="deleteHotel(0)"
              :disabled="isLoadingArrayDelete[0] || isDeleteButtonDisabled"
            >
              <div v-if="isLoadingArrayDelete[0]" class="lds-dual-ring"></div>
              <span v-else>Delete</span>
            </button>
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="editHotel(0)"
              :disabled="isLoadingArray[0] || isUpdateButtonDisabled"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0] || isUpdateButtonDisabled"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
  maxLength,
  minLength,
  integer,
} from "vuelidate/lib/validators";
import { clipperBasic, clipperPreview } from "vuejs-clipper";
import _ from "lodash";
import axios from "axios";

export default {
  name: "EditHotel",
  components: {
    clipperBasic,
    clipperPreview,
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      isUpdateButtonDisabled: false,
      isDeleteButtonDisabled: false,
      isLoadingArrayDelete: [],
      details: {
        name: "",
        mob: "",
      },
      url: "",
      photoUrl: null,
      hotelAvatar: [],
      hotelFinalAvatar:  "",
      hotelRawData: "",  
      isValid: true,
      hotelPhoto: null,
      hotel: {},
      location: "",
      lan: "",
      long: "",
      marker: { position: { lat: 10, lng: 10 } },
      markers: [],
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: false,
      },
      city: "",
      state: "",
      zipCode: "",
      street: "",
    };
  },
  validations: {
    details: {
      name: { required, maxLength: maxLength(18) },
      mob: { required, integer, minLength: minLength(11) },
    },
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
  },

  methods: {
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    handleThrottledMarkerDrag: _.throttle(function(event) {
      this.handleMarkerDrag(event);
    }, 50),
    handleMarkerDrag(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function(results, status) {
        if (status === "OK") {
          if (results[1]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "locality"
                  ) {
                    window.test.city =
                      results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route" ||
                results[i]?.types[0] == "neighborhood"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] ==
                      "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "postal_code"
                  ) {
                    window.test.zipCode =
                      results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    dataReset: function() {
      this.details.name = "";
      this.details.mob = "";
      this.url = "";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.lan = "";
      this.long = "";
      this.location = "";
      this.street = "";
      this.$v.$reset();
      this.hotelAvatar = [];
      this.hotelrawData = "";
      this.hotelFinalAvatar = "";
      this.isDisabled = true;
      this.hotelPhoto = null;
      this.photoUrl = null;
      document.getElementById("vue-file-upload-input-hotel-photo-edit").value = "";
    },
    dataReset1: function() {
      this.isValid =true;
      this.hotelAvatar = [];
      this.hotelrawData = "";
      },

    errorNull: function() {
      if (
        (this.details.name == "" &&
          this.url == "" &&
          this.details.mob == "" &&
          this.city == "") ||
        this.state == "" ||
        this.zipCode == "" ||
        this.street == "" ||
        this.state == null ||
        this.city == null ||
        this.street == null ||
        this.zipCode == null ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    setPlace(place) {
      if (!place) return;
      this.$refs.autocompleteRef.$el.classList.add("custom-dropdown-open");
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.street = place.formatted_address;
      this.location = place.formatted_address;
      const addressComponents = place.address_components;

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          this.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        } else if (types.includes("postal_code")) {
          this.zipCode = component.long_name;
        }
      }
      this.panToMarker();
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
    },
    handleMapClick(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function(results, status) {
        if (status === "OK") {
          if (results[0]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "locality"
                  ) {
                    window.test.city =
                      results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] ==
                      "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state =
                      results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "postal_code"
                  ) {
                    window.test.zipCode =
                      results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },

    change(e) {
      const url = e.target.value;
      this.isURLValid(url);
    },
    isURLValid(inputUrl) {
      var regx = new RegExp(
        "^" +
          // protocol identifier
          "(?:(?:https?|http)://)" +
          // user:pass authentication
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broacast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host name
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          // domain name
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          // TLD identifier
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          ")" +
          // port number
          "(?::\\d{2,5})?" +
          // resource path
          "(?:/\\S*)?" +
          "$",
        "i"
      );
      this.isValid = regx.test(inputUrl);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-hotel-photo-edit").click();
    },
    onFileChange(event) {
  console.log("hello")
  const self = this;
  var fileInput = document.getElementById("vue-file-upload-input-hotel-photo-edit");
  var file_ = fileInput.value;
  var files = event.target.files;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  
  console.log("File input value:", file_);
  console.log("Files array:", files);
  
  if (!allowedExtensions.exec(file_)) {
    alert("Please upload file having extensions .jpeg/.jpg/.png/.gif only.");
    fileInput.value = "";
    return false;
  } else {
    const file_ = files[0];
    console.log("Selected file:", file_);
    console.log("File name:", file_.name);
    console.log("File size:", file_.size);
    
    this.hotelAvatar.push({
      target: event.target.files[0],
      fileName: file_.name,
      link: URL.createObjectURL(file_),
    });
    this.hotelrawData = URL.createObjectURL(file_);
    console.log("Hotel avatar array:", this.hotelAvatar);
    
    let reader = new FileReader();
    reader.onload = function(e) {
      self.hotelrawData = e.target.result;
      console.log("Raw data:", self.hotelrawData);
    };
    reader.readAsDataURL(file_);
    
    this.errorNull();
  }
},
    dataURItoBlob: function(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    // dataURItoBlob: function (dataURI) {
    //   // convert base64/URLEncoded data component to raw binary data held in a string
    //   var byteString;
    //   if (dataURI.split(",")[0].indexOf("base64") >= 0)
    //     byteString = atob(dataURI.split(",")[1]);
    //   else byteString = unescape(dataURI.split(",")[1]);

    //   // separate out the mime component
    //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    //   // write the bytes of the string to a typed array
    //   var ia = new Uint8Array(byteString.length);
    //   for (var i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //   }

    //   return new Blob([ia], { type: mimeString });
    // },
    closeModal(){
      const elem = this.$refs.closeEditHotel;
          if (elem) {
            elem.click();
          }
    },
    async editHotel(index) {
      // Set the loading state to indicate that the edit process is ongoing
      this.$set(this.isLoadingArray, index, true);
      this.isUpdateButtonDisabled = true;
      this.isDeleteButtonDisabled = true;

      // Prepare the data to be sent in the request
      const formData = new FormData();
      formData.append("id", this.hotel.id); // Assuming hotel.id is available
      formData.append("association_id", this.association_id); 
      formData.append("name", this.details.name);
      formData.append("website_url", this.url);
      formData.append("mob", this.details.mob);
      formData.append("city", this.city);
      formData.append("state", this.state);
      formData.append("zip", this.zipCode);
      formData.append("street_address", this.street);
      formData.append("lan", this.marker.position.lat);
      formData.append("long", this.marker.position.lng);

      if (this.hotelAvatar.length > 0) {
        const canvas = this.$refs.clipper.clip();
        var dataURL = canvas.toDataURL("image/jpeg", 0.95);
        var blob = this.dataURItoBlob(dataURL);
        this.hotelAvatar.forEach((value, index) => {
          formData.append("image_url[" + index + "]", blob);
        });
      }

      try {
        // Make an HTTP POST request to the backend API
        const response = await axios.post("/associationEvent/editEventHotel", formData);

        // Handle the response based on statusCode
        if (response.data.statusCode === 200) {
          // Successful response
          console.log("Hotel updated successfully:", response.data);
          this.dataReset();
          this.$root.$emit("hotelAddedEmit");

          this.closeModal();
        } else if (response.data.statusCode === 404) {
          this.closeModal();
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        } else {
          // Handle other statuses
          console.error("Error: ", response.data.message || "Unknown error occurred");
          this.$alert(response.data.message || "An error occurred while updating the hotel", 'Error');
        }
      } catch (error) {
        // Handle errors that occur during the request
        console.error("Error updating hotel:", error);
        this.$alert('An error occurred while updating the hotel. Please try again later.', 'Network Error');
      } finally {
        // Reset loading and button states
        this.isUpdateButtonDisabled = false;
        this.isDeleteButtonDisabled = false;
        this.$set(this.isLoadingArray, index, false);
      }
    },


    deleteHotel(index) {
      try {
        this.isLoadingArrayDelete[index] = true;
        this.isUpdateButtonDisabled = true;
        this.isDeleteButtonDisabled = true;

        // Prepare the payload with both hotel ID and association ID
        const payload = {
          id: this.hotel.id,
          association_id: this.association_id, // Make sure this.association_id is defined in your component
        };

        axios.post('/associationEvent/deleteHotel', payload)
          .then(response => {
            if (response.data.statusCode === 200) {
              this.$alert(response.data.message);
              this.$root.$emit("hotelAddedEmit");
             this.closeModal();
            } else if (response.data.statusCode === 403) {
              this.closeModal();
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
            } else if (response.data.statusCode === 404) {
              this.$alert(response.data.message);
              this.$root.$emit("hotelAddedEmit");
              this.closeModal();
            }
          })
          .catch(error => {
            console.error('Error deleting hotel:', error);
            if (error.response && error.response.data && error.response.data.message) {
              this.$alert(error.response.data.message);
            } else {
              this.$alert('An error occurred while deleting the hotel.');
            }
          })
          .finally(() => {
            this.isLoadingArrayDelete[index] = false;
            this.isUpdateButtonDisabled = false;
            this.isDeleteButtonDisabled = false;
          });
      } catch (error) {
        console.error('Error deleting hotel:', error);
        this.isLoadingArrayDelete[index] = false;
        this.isUpdateButtonDisabled = false;
        this.isDeleteButtonDisabled = false;
      }
    }


  },

  mounted() {
    this.$root.$on("openEditHotelModal", (hotel) => {
      console.log(hotel)
      this.hotel = hotel;
      this.details.name = hotel.name;
      this.details.mob = hotel.phone_number;
      this.url = hotel.website_url;
      this.city = hotel.city;
      this.location= hotel.street_address;
      this.state = hotel.state;
      this.zipCode = hotel.zip;
      this.marker.position.lng = hotel.latitude;
      this.marker.position.lat = hotel.longitude;
      let lat = parseFloat(hotel.latitude);
      let lng = parseFloat(hotel.longitude);
      this.marker.position = {
        lat: lat,
        lng: lng,
      };
      this.street = hotel.street_address;
      if (hotel.image_url != null) {
        this.hotelFinalAvatar = hotel.image_url;
        this.hotelrawData = this.$eventHotelStorageURL + this.hotelFinalAvatar; 
      } else  {
        this.hotelFinalAvatar = "";
      }
      var elem = this.$refs.openModalEditHotel;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style scoped>
.vue-map-container,
.vue-map-container .vue-map {
  width: 100% !important;
}
.profile-preview {
  display: flex;
  gap: 10px;
  align-items: end;
}

.profile-photo-edit {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
  cursor: pointer;
}

.profile-photo-edit .user-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.error-sponser{
        left: auto;
        margin-top: 15px;
color: white;
border-radius: 5px;
        right: 5%;
        background-color: #ff5b66 !important;
        transform: translate(-5%, -50%);
        -webkit-transform: translate(-5%, -50%);
        -moz-transform: translate(-5%, -50%);
        -ms-transform: translate(-5%, -50%);
        -o-transform: translate(-5%, -50%);
        width: max-content;
}

.profile-photo-edit img,
.new-profile-photo img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.profile-photo-edit-icon-wrapper {
  position: absolute;
  top: 76.5%;
  background-color: #e5f2fe;
  right: 3.8%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.profile-photo-edit-icon-wrapper img {
  width: 20px;
}
</style>
