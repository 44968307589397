<template>
  <div>
    <div class="new my-2" v-if="showLoader == 1">
      <div class="friends-requests p-0">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
        </content-placeholders>
      </div>
    </div>

    <router-link v-if="showLoader == 0" class="mx-0" exact :to="{ name: 'TeamStatistics', params: { teamId: selectedRoomTeam.roomId } }">
      <div class="main-page-event my-2">
        <div class="top-layer">
          <div class="left-sec">
            <div class="nav-link p-0 pl-2">
              <p class="event-tag">Team Record</p> 
            </div>
          </div>
          <div class="right-sec">
            <!-- <img src="../../../../assets/images/info-circle.png" alt="" /> -->
          </div>
        </div>
        <div class="row no-gutters mt-2">
          <div class="col-12 col-sm-4">
            <div class="counter-widget mr-0">
              <h3>Games</h3>
              <span class="counter-value">{{ totalGame }}</span>
            </div>
          </div>
          <div class="col-6 col-sm-4 pl-0 pr-2 px-sm-2 mt-2 mt-sm-0">
            <div class="counter-widget">
              <h3>Won</h3>
              <span class="counter-value">{{ win }}</span>
            </div>
          </div>
          <div class="col-6 col-sm-4 mt-2 mt-sm-0">
            <div class="counter-widget mr-0">
              <h3>Lost</h3>
              <span class="counter-value">{{ loss }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "TeamRecord",
  props: {
    showLoader: {
      type: Number,
    },
    selectedRoomTeam: {
      type: Object,
    },
   totalGame: {
      type: Number,
    },
    win: {
      type: Number,
    },
    loss: {
      type: Number,
    },
  },
};
</script>