<template>
  <div>
    <EditMessageModal :messageData="messageData" :association_id="association_id" />
    <div class="products-list-shim p-0 mb-2" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
    <div v-if="messageData.length !== null && showLoader == 0">
      <div class="d-md-flex align-items-center justify-content-between">
        <button class="custom-btn m-0" @click="backToMain()">
          <router-link
            :to="{
              name: 'AssociationSingleEventDetail',
              params: { id: messageData?.event?.guid },
            }"
          >
            <img src="../../assets/images/blue-arrow-left.png" alt="" />
            <span
              >Back to Message Board - {{ messageData?.event?.eventName }}</span
            >
          </router-link>
        </button>
        <button class="custom-btn-two" @click="openEditModal()" v-if="userId == messageData?.user_id || currentIsAdmin == '1'">
          <img src="../../assets/images/edit.png" alt="" />
          <span>Edit Message</span>
        </button>
      </div>
      <p class="sender-date">
        {{ messageData?.user?.fname || '' }} {{ messageData?.user?.lname || '' }} -
        <span>{{ formatDate(messageData?.message_date) }}</span>
      </p>
      <p class="message-topic">{{ messageData?.message_subject }}</p>
      <p class="message-content-info">
        {{ messageData?.message_body }}
      </p>
      <div class="d-md-flex align-items-center justify-content-between my-2">
        <div class="icons-side">
          <div class="back-square">
            <img src="../../assets/images/back-square.png" alt="" />
            <span>{{ messageData?.replies_count }}</span>
          </div>
          <div class="back-square">
            <img src="../../assets/images/message-eye.png" alt="" />
            <span>{{ messageData?.views_count }}</span>
          </div>
        </div>
        <p
          v-if="messageData.allow_replies === '0'"
          class="message-replies text-md-right"
        >
        Replies to this message are turned off. 
        </p>
      </div>
      <div
        class="comment-input message-side-wrapper py-3"
        v-if="messageData.allow_replies === '1'"
      >
        <div class="wrapper">
          <input
          ref="inputCommentField"
          v-model="writtenComment"
          placeholder="Write a reply to this message"
          @keyup.enter="handleEnter()"
          class="regular-input"
          maxlength="1000"
          :disabled="isSendingComment"
        />

          <emoji-picker @emoji="append" :search="search">
            <button
              class="emoji-invoker"
              slot="emoji-invoker"
              slot-scope="{ events: { click: clickEvent } }"
              @click.stop="clickEvent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                class="bi bi-emoji-smile"
                viewBox="0 0 24 24"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"
                />
              </svg>
            </button>
            <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
              <div
                class="emoji-picker"
                :style="{ top: display.y + 'px', left: display.x + 'px' }"
              >
                <div>
                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                    <h5>{{ category }}</h5>
                    <div class="emojis">
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                        >{{ emoji }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </emoji-picker>
        </div>
        <button class="comment-btn" @click="createComment" :disabled="isSendingComment || isCommentEmpty">
          <img src="../../assets/images/send.png" alt="send-btn" />
        </button>
      <div v-if="isSendingComment">
        <content-placeholders :rounded="true">
            <content-placeholders-heading :img="false" />
          </content-placeholders>
      </div>

      </div>
    </div>
    <!-- <div class="products-list-shim" v-if="showLoader1 == 1 && replies.length == 0 ">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="3" />
      </content-placeholders>
    </div> -->
    <div
      class="products-list-shim p-0"
      v-if="showLoader1 == 1 && replies.length == 0"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>

    <div v-if="replies.length > 0">
      <div class="replies-sec">
        <h6 class="mb-2">Replies</h6>
        <div>
          <content-placeholders :rounded="true" v-if="showReplyLoader == 1">
            <content-placeholders-heading :img="false" />
          </content-placeholders>
          <div
            v-for="(reply, index) in replies"
            :key="index"
            class="reply-content mt-2"
          >
            <div
              class="replier-info d-flex align-items-center justify-content-between"
            >
              <div class="info-wrap">
                <div class="people-img">
                  <div class="users-notification" v-if="imgLoader == 1">
                    <!-- <div class="lds-dual-ring-refresh"></div> -->
                    <img src="../../assets/loader.gif" alt="" />
                  </div>
                  <img
                    @load="onImageLoad"
                    v-if="reply.user.picture == 'yes'"
                    :src="API_URL + reply.user.id + '/profile_picture.jpg?v='"
                    alt=""
                  />
                  <img
                    @load="onImageLoad"
                    v-else
                    src="../../assets/images/user-default-new.png"
                    alt="user"
                  />
                </div>
                <div class="user-details mr-auto">
                  <span>{{ (reply?.user?.fname || '') + ' ' + (reply?.user?.lname || '') }}</span>
                  <p>{{ formatDate(reply?.message_date) }}</p>
                </div>
              </div>
              <div class="opt edit-btn mt-0" v-if="userId == messageData?.user_id || currentIsAdmin == '1' || reply?.user?.id == userId">
                <EllipsisMenu :reply="reply" @deleteReply="deleteReply" />
              </div>
            </div>
            <p class="reply px-2 pt-1">{{ reply?.message_body }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="replies.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>

    <div
      class="no-products-in-shop"
      v-if="(!replies || replies.length === 0) && showLoader1 === 0"
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No replies found.</p>
      <p class="info">
        There are no replies to display.
      </p>
    </div>
  </div>
</template>

<script>
import EmojiPicker from "vue-emoji-picker";
import axios from "axios";
import EllipsisMenu from "./Modals/MessageDelation.vue";
import EditMessageModal from "./Modals/EditMessageModal.vue";
export default {
  name: "EditMessage",
  components: { EmojiPicker, EditMessageModal, EllipsisMenu },
  props: {
    messageId: {
      type: String,
      required: true,
    },
    messageData: {
      type: Object,
    },
    replies: {
      type: Array,
      required: true,
    },
    showLoader: {
      type: Number,
      default: 1,
    },
    showLoader1: {
      type: Number,
      default: 1,
    },
    showReplyLoader: {
      type: Number,
      default: 1,
    },
    msgLastPage: {
      type: Number,
    },
    page: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
  },
  data() {
    return {
      writtenComment: "",
      search: "",
      imgLoader: 1,
      API_URL: this.$userProfileStorageURL,
      userId: localStorage.getItem("userId"),
      deleteOption: false,
      isSendingComment: false,
    };
  },
  computed: {
    isCommentEmpty() {
      return this.writtenComment.trim() === '';
    }
  },
  methods: {
    openEditModal() {
      this.$root.$emit("editMessageModal", this.messageData);
    },
    deleteMessageOption() {
      this.deleteOption = !this.deleteOption;
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.msgLastPage) {
        return;
      }
      if (isVisible) {
        this.$root.$emit("replies-increment"); // Emit event to notify parent component
      }
    },
    onClickOutside() {
      this.deleteOption = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleDateString("en-US", options);
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    handleEnter() {
      if (!this.isCommentEmpty && !this.isSendingComment) {
        this.createComment();
      }
    },
    createComment() {
      this.showReplyLoader = 1;
      if (this.isSendingComment) return;
      this.isSendingComment = true;

      const requestData = {
        event_id: this.messageData.event_id,
        association_id: this.association_id,
        topic_id: this.messageData.topic_id,
        parent_id: this.messageData.id,
        message_subject: this.messageData.message_subject,
        message_body: this.writtenComment,
        message_date: new Date().toISOString(),
      };

      axios
        .post(`${process.env.VUE_APP_API_URL}associationEvent/addReply`, requestData)
        .then((response) => {
          // Handle the status code in the response
          if (response.data.statusCode === 200) {
            // Success: update reply count, reset comment, emit fetch replies event
            this.messageData.replies_count++;
            this.$root.$emit("fetchRepliesEmit");
            this.writtenComment = "";
            this.showReplyLoader = 0;
            console.log("Comment created successfully.");
          } else if (response.data.statusCode === 404 || response.data.statusCode === 403) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              this.showLoaderUmpire = 0;
            } else {
            // Handle other status codes as needed
            console.error("Unexpected status code:", response.status);
          }
        })
        .catch((error) => {
          if (error.response) {
            // Handle the error based on the status code from the response
            console.error("Failed to create comment:", error.response.status, error.response.data.message);
          } else {
            // Other errors (e.g., network issues)
            console.error("Failed to create comment:", error.message);
          }
          this.showReplyLoader = 1;
        })
        .finally(() => {
          this.isSendingComment = false;
          this.showReplyLoader = 0; // Set loader to false after the request
        });
    },
    deleteReply(reply) {
      this.showReplyLoader = 1;
      const requestData = {
        reply_id: reply.id,
        association_id: this.association_id,
      };

      axios
        .post(`${process.env.VUE_APP_API_URL}associationEvent/deleteReply`, requestData)
        .then((response) => {
          const { statusCode, message } = response.data;

          // Check the statusCode to determine success or failure
          if (statusCode === 200) {
            // Reply successfully deleted
            this.messageData.replies_count--;
            this.showReplyLoader = 0;
            this.$root.$emit("fetchReplyAfterDelation");
            console.log("Reply deleted successfully:", message);
          } else if (statusCode === 404) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              this.showReplyLoader = 0;
            } else {
            // Handle error case (e.g., user is unauthorized, or other failure)
            console.error("Failed to delete reply:", message);
            this.showReplyLoader = 0;
            // Optionally show an alert or message to the user
          }
        })
        .catch((error) => {
          // Handle the error from the server
          console.error("Failed to delete reply:", error);
          this.showReplyLoader = 0;
          // Optionally display an error message to the user
        });
    },
    backToMain() {
      this.$router.push({
        name: "AssociationSingleEventDetail",
      });
    },
    append(emoji) {
      this.writtenComment += emoji;
      this.$refs.inputCommentField.focus();
    },
  },
};
</script>

<style></style>
