<template>
  <div>
    <SettingHead :selectedRoomTeam="selectedRoomTeam" v-if="showHead == 0" />

    <EventDetails
      v-if="showEventDetail == 1"
      :singleEvent="singleEvent
      
      
      "
      :selectedRoomTeam="selectedRoomTeam"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
      :currentIsFan="currentIsFan"
      :currentTeamIsActive="currentTeamIsActive"
    />
    <div class="chat-right-sidebar">
      <!-- <AdsOnHeader v-if="showMainComponents == 0 && showEventDetail == 0" /> -->
      <TeamRecord   :selectedRoomTeam="selectedRoomTeam" :showLoader="showLoader" :totalGame="totalGame" :win="win" :loss="loss" v-if="showMainComponents == 0 && showEventDetail == 0 && selectedRoomTeam.teamType == 'sports'" />
      <EventMain
        v-if="
          showEventMainComponents == 0 &&
          showMainComponents == 1 &&
          showEventDetail == 0
        "
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
        :currentIsAdmin="currentIsAdmin"
        :currentIsFan="currentIsFan"
        :currentTeamIsActive="currentTeamIsActive"
      />
      <SettingMenu
        v-if="showMenuComponents == 0 && showEventDetail == 0"
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
        :currentIsAdmin="currentIsAdmin"
        :currentIsFan="currentIsFan"
        :currentTeamIsActive="currentTeamIsActive"
      />
    </div>
    <!-- </transition> -->
  </div>
</template>
<script>
import SettingHead from "../ChatRight/ChatSettingComponents/SettingHead";
// import AdsOnHeader from "../ChatRight/ChatSettingComponents/AdsOnHeader.vue";
import SettingMenu from "../ChatRight/ChatSettingComponents/SettingMenu";
import EventMain from "../ChatRight/ChatEvents/EventMain.vue";
import EventDetails from "../ChatRight/ChatEvents/EventDetails.vue";
import TeamRecord from "./ChatSettingComponents/TeamRecord.vue";

export default {
  components: {
    SettingHead,
    // AdsOnHeader,
    TeamRecord,
    SettingMenu,
    EventMain,
    EventDetails,
  },
  name: "ChatSettings",
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentIsFan: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    showLoader: {
      type: Number,
    },
   totalGame: {
      type: Number,
    },
    win: {
      type: Number,
    },
    loss: {
      type: Number,
    },
  },
  data: function () {
    return {
      showMainComponents: 0,
      showEventMainComponents: 1,
      showMenuComponents: 0,
      showEventDetail: 0,
      showHead: 0,
      singleEvent: [],
    };
  },

  mounted() {
    this.$root.$on("ChatSettings", (id) => {
      this.showMainComponents = id;
      this.showEventMainComponents = 1;
      this.showMenuComponents = 0;
      this.showEventDetail = 0;
      this.showHead = id;
      this.$root.$emit("MenuSettingsDiv", id);
    });

    this.$root.$on("ChatSettingEvent", (id, eventId) => {
      this.showMainComponents = id;
      this.showEventMainComponents = eventId;
      this.showMenuComponents = 1;
      this.showHead = 1;
    });

    this.$root.$on("showEventDetailPage", (event) => {
      this.showEventDetail = 1;
      this.singleEvent = event;
      this.showEventMainComponents = 1;
      this.showMenuComponents = 1;
      this.showHead = 1;
    });
    this.$root.$on("showEventDetailPageBack", () => {
      if (this.showEventDetail == 1) {
        this.showEventDetail = 0;
        this.singleEvent = [];
        this.showMainComponents = 1;
        this.showEventMainComponents = 0;
        this.showHead = 1;
      }
    });
  },
};
</script>
<style scoped>
</style>
