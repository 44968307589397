<template>
  <div>
    <!-- Select Home Team -->
    <input
      class="d-none"
      data-target="#homeTeamModal"
      data-toggle="modal"
      ref="openSelectHomeTeam"
      @click="checkCrud()"
    />
    <div
      class="modal fade"
      id="homeTeamModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">Select Home Team</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closehomeTeamModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @keyup.enter="selectHomeTeam(0)" @submit.prevent>
              <input
                type="radio"
                id="team"
                v-model="selectHomeTeamRadio"
                value="1"
                checked="checked"
              />
              {{ selectedRoomTeam.teamName }}
              <br />
              <input
                type="radio"
                id="opponent"
                class="my-2"
                v-model="selectHomeTeamRadio"
                value="2"
              />
              {{ singleGame.opponent_name }}
              <div class="form-group">
                <label for="startTimeCheck">Start Time</label>
                <!-- <span
                      class="label-type error event-error ml-2"
                      v-if="timeError != ''"
                    >
                      Event Time is Invalid.
                    </span> -->
                <div
                  class="input-group time"
                  id="startTimeCheck"
                  data-target-input="nearest"
                >
                  <vue-timepicker
                    id="startTime"
                    class="time-input"
                    placeholder="10:00 AM"
                    format="hh:mm A"
                    required="required"
                    v-model="startTime"
                    v-on:change="checkTime"
                    close-on-complete
                    hide-clear-button
                  >
                    <template v-slot:icon>
                      <img src="../../../assets/images/clock-input.png" />
                    </template>
                  </vue-timepicker>
                </div>
              </div>
              <div class="form-group">
                <label for="timeLimit">Time Limit</label>
                <input
                  type="number"
                  class="form-control"
                  id="timeLimit"
                  v-model="timeLimit"
                  required
                  placeholder="Minutes"
                  v-limit-digits
                  @keyup="errorNull"
                />
              </div>
            </form>
          </div>

          <div class="event-btns-wrapper px-3 pt-0 pb-3 justify-content-end">
            <button
              v-if="isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              @click="selectHomeTeam(0)"
              :disabled="isLoadingArraySelectTeam[0]"
            >
              <div
                v-if="isLoadingArraySelectTeam[0]"
                class="lds-dual-ring"
              ></div>
              <span v-else>Start Game</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="true"
            >
              <div
                v-if="isLoadingArraySelectTeam[0]"
                class="lds-dual-ring"
              ></div>
              <span v-else>Start Game</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
export default {
  name: "KeepScores",
  data: function () {
    return {
      isDisabled: true,
      isLoadingArraySelectTeam: [],
      singleGame: [],
      selectedRoomTeam: [],
      selectHomeTeamRadio: 1,
      opponentFlag: 0,
      exactStartTime: "10:00 AM",
      startTime: "10:00 AM",
      timeLimit: "",
    };
  },
  components: {
    VueTimepicker,
  },
  directives: {
    limitDigits: {
      bind(el, binding, vnode) {
        el.addEventListener("input", function (event) {
          const maxLength = 2; // Change this value to limit to a different number of digits
          const input = event.target;
          const inputValue = input.value.replace(/\D/g, ""); // Remove non-numeric characters
          if (inputValue.length > maxLength) {
            input.value = inputValue.slice(0, maxLength);
          } else {
            input.value = inputValue;
            vnode.context.battingStats[binding.expression] = parseInt(
              inputValue,
              10
            ); // Update the data model
          }
        });
      },
    },
  },
  methods: {
    errorNull() {
      if (this.timeLimit == "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    // Select Home Team
    async selectHomeTeam(index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArraySelectTeam, index, true);
      this.opponentFlag = this.selectHomeTeamRadio == 2 ? 0 : 1;


      const formData = new FormData();
      formData.append("game_id", this.singleGame.id);
      formData.append("team_id", this.selectedRoomTeam.id);
      formData.append("team_name", this.selectedRoomTeam.teamName);
      formData.append("team_avatar", this.selectedRoomTeam.teamAvatar);
      formData.append("opponent_flag", this.opponentFlag);
      formData.append("actual_start_time", this.exactStartTime);
      formData.append("time_limit", this.timeLimit);

      await axios
        .post(`${process.env.VUE_APP_API_URL}game/selectHomeTeam`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode === 404) {
            // Handle 404 status code by showing an alert or error message
            this.$alert(response.data.message, "Error");
            this.$set(this.isLoadingArraySelectTeam, index, false);
            this.$router.push({ name: "Teams" });
            this.isDisabled = false;
          } else if (response.data.statusCode === 200) {
            // Successful response handling
            this.$set(this.isLoadingArraySelectTeam, index, false);

            const close = this.$refs.closehomeTeamModal;
            if (close) {
              close.click();
            }

            this.isDisabled = false;
            this.$root.$emit("showSingleBoxScore");
          } else {
            // Handle other status codes if necessary
            console.log(response.data.message);
            this.$set(this.isLoadingArraySelectTeam, index, false);
            this.isDisabled = false;
          }
        })
        .catch((error) => {
          console.error("An unexpected error occurred:", error);
          this.$set(this.isLoadingArraySelectTeam, index, false);
          this.isDisabled = false;
        });
    },
    checkCurd() {
      this.exactStartTime = "11:00 AM";
      this.startTime = "11:00 AM";
      this.timeLimit = "";
    },
    checkTime: function () {
      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          hours -= 12;
        } else if (hours < 12) {
          if (hours == 0) {
            hours = 12;
          }
        }
        this.exactStartTime = hours + ":" + minutes;
        this.isDisabled = false;
      }
      this.errorNull();
    },
  },
  mounted() {
    this.$root.$on(
      "openSelectHomeTeamModal",
      (singleGame, selectedRoomTeam) => {
        this.singleGame = singleGame;
        this.startTime = this.singleGame.start_time;
        this.selectedRoomTeam = selectedRoomTeam;
        var elem = this.$refs.openSelectHomeTeam;
        if (elem) {
          elem.click();
        }
      }
    );
  },
};
</script>

<style scoped>
.inning-btns {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inning-btns button {
  outline: none;
  margin: 0 5px;
  border-radius: 5px;
  background-color: #188ef5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  border: 1px solid #188ef5;
  transition: all 0.7s ease;
}

.inning-btns button:last-child {
  margin-right: 0;
}

.inning-btns button:hover {
  border: 1px solid #188ef5;
  color: #188ef5;
  background-color: transparent;
}

.table {
  text-align: center;
  font-size: 13px;
}

.table-innings {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.table thead th,
.table tbody td {
  height: 85px;
}

.table.table-teams tbody tr:nth-child(1) {
  height: 86px !important;
}

.table-innings::-webkit-scrollbar {
  height: 7.5px;
}

.table-innings::-webkit-scrollbar-track {
  background-color: none;
}

.table-innings::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.loader-table {
  height: 152px;
}

.bg-edit {
  background-color: #e2f5fe;
}

@media screen and (max-width: 576px) {
  .inning-btns button {
    margin: 0 0 0 5px;
    border-radius: 5px;
    background-color: #188ef5;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    padding: 5px;
    border: 1px solid #188ef5;
    transition: all 0.7s ease;
  }

  .table {
    font-size: 10px;
  }

  .table-total,
  .table-teams {
    width: 100px;
  }

  .table-innings {
    max-width: 500px !important;
    overflow-x: auto;
  }

  .table th {
    padding: 0.75rem 0.3rem;
  }
}

.table tbody + tbody {
  border: none !important;
}
.table.table-teams .team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (min-width: 768px) {
  .table-total {
    width: 160px;
  }
  .table-teams {
    width: 300px;
  }
}

.table-innings {
  width: 500px;
  overflow-x: auto;
}

.table-bottom-none {
  border-bottom: none !important;
}

@media screen and (max-width: 413px) {
  .inning-btns {
    display: inline-block;
  }
  .inning-btns button {
    margin-bottom: 5px;
  }
}
</style>
