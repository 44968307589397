<template>
  <div class="head-main">
    <div class="team-info team-right-info">
      <span class="main-back-arrow" @click="showChat">
        <img src="../../../../assets/images/arrow-left.png" alt="" />
      </span>
      <div class="team-content-wrapper">
        <div class="image-wrapper">
          <div class="shop-products-img-loader" v-if="showLoader == 1">
            <div class="lds-dual-ring-media"></div>
          </div>
          <img
            @load="onImageLoad"
            v-if="selectedRoomTeam.teamAvatar == ''"
            src="../../../../assets/images/main-team-avatar.png"
            alt=""
          />
          <img
            @load="onImageLoad"
            v-if="selectedRoomTeam.teamAvatar != ''"
            :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
            alt=""
            @click="OpenTeamPhotoPreview"
          />
        </div>
        <div class="cover-photo-modal" v-if="openTeamPhoto">
          <button
            class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
            @click="CloseTeamPhotoPreview"
          >
            <i class="fa fa-times"></i>
          </button>
          <div class="cover-img-full-preview" @click="CloseTeamPhotoPreview">
            <img
              @load="onImageLoad"
              v-if="selectedRoomTeam.teamAvatar != ''"
              :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
              alt=""
            />
          </div>
        </div>
        <div class="user-details">
          <span class="team-name" :id="selectedRoomTeam.id">
            {{ selectedRoomTeam.teamName }}
          </span>
          <div>
            <p
              class="team-type"
              v-if="
                selectedRoomTeam.teamType == 'sports' &&
                selectedRoomTeam.sportType != undefined
              "
            >
              <span>{{ selectedRoomTeam.teamType }} </span>
              <span>({{ selectedRoomTeam.sportType }})</span>
            </p>
            <p class="team-type" v-else>
              <span>{{ selectedRoomTeam.teamType }}</span>
            </p>
            <p class="gender-group">
              <span
                v-if="
                  selectedRoomTeam.teamType == 'sports' &&
                  selectedRoomTeam.sportType != undefined
                "
              >
                {{ selectedRoomTeam.gender }} -
              </span>
              <span
                v-if="
                  selectedRoomTeam.teamType == 'sports' &&
                  selectedRoomTeam.sportType != undefined
                "
              >
                {{ selectedRoomTeam.ageGroup }}</span
              >
            </p>

            <!-- <p class="gender-group">
              Created By
              <span>{{ selectedRoomTeam.createdBy }}</span> on
              <span>{{ selectedRoomTeam.createdAt | formatDateChat }}</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "SettingHead",
  data: function () {
    return {
      showLoader: 1,
      openTeamPhoto: false,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
  },
  methods: {
    OpenTeamPhotoPreview() {
      this.openTeamPhoto = true;
    },
    CloseTeamPhotoPreview() {
      this.openTeamPhoto = false;
    },
    onImageLoad() {
      this.showLoader = 0;
    },
    showChat() {
      let window_width = window.innerWidth;
      if (window_width <= 991.98) {
        $(".chat-right").hide();
        $(".vac-col-messages").show();
        this.$root.$emit("ChatSettings", 0);
        this.$root.$emit("fetchEvents");
      } else if (window_width >= 992) {
        $(".chat-right").show();
        $(".vac-col-messages").show();
      }
    },
  },
};
</script>
