<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#manageSeedars"
      ref="openModalManageSeedars"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="manageSeedars">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Manage Event Seedars</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeManageSeedars"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="teammates-lineup-wrapper">
              <div class="available-teammates">
                <h6>Available Seedars</h6>
                <div class="teammates-info">
                  <draggable
                    class="list-group"
                    :list="availableSeedars"
                    group="people"
                    handle=".handle"
                    @change="handleAvailableSeedars"
                    v-if="showLoaderAvailableSeeadrs == 0"
                  >
                    <div
                      class="list-group-item"
                      v-for="(element, index) in availableSeedars"
                      :key="index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                          <p class="name">
                            {{
                              element?.criteria_name ||
                                element?.seed_criteria.criteria_name
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="handle">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderAvailableSeeadrs == 0 &&
                        availableSeedars.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No available seedars found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderAvailableSeeadrs == 1"
                  ></div>
                </div>
              </div>
              <div class="team-lineup">
                <h6>Selected Seedars</h6>
                <div class="teammates-info position-relative">
                  <draggable
                    class="list-group"
                    :list="selectedSeedars"
                    group="people"
                    handle=".handle"
                    @change="handleSelectedSeedars"
                    v-if="showLoaderSelectedSeedars == 0"
                  >
                    <div
                      class="list-group-item"
                      v-for="(element, index) in selectedSeedars"
                      :key="element?.position_index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                          <p class="name">{{ element?.criteria_name }}</p>
                        </div>
                      </div>
                      <div class="handle">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderSelectedSeedars == 0 &&
                        selectedSeedars.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No selected seedars found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderSelectedSeedars == 1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="manageSeedars(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import draggable from "vuedraggable";
export default {
  name: "ManageEventSeedars",
  components: {
    draggable,
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      selectedSeedars: [],
      showLoaderSelectedSeedars: 1,
      availableSeedars: [],
      showLoaderAvailableSeeadrs: 1,
    };
  },
  watch: {
    selectedSeedars: function(newValue) {
      if (newValue.length > 0 || this.availableSeedars.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    availableSeedars: function(newValue) {
      if (newValue.length > 0 || this.selectedSeedars.length > 0) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },

  methods: {

    closeModal(){
      var elem = this.$refs.closeManageSeedars;
        if (elem) {
          elem.click(); 
        }
    },
    async manageSeedars(index) {
    this.$set(this.isLoadingArray, index, true); // Show loading indicator

    try {
      const url = `${process.env.VUE_APP_API_URL}associationEvent/updateEventSeedCriteria`;
      const response = await axios.post(url, {
        event_id: this.eventId,
        association_id: this.association_id,
        seed_criteria_defaults: this.selectedSeedars,
      });

      // Check the response and handle accordingly
      if (response.data.statusCode === 200) {
        // Success case
        this.$root.$emit("fetchUpdatedSeedCriteria"); 
        this.closeModal();
        this.$alert('Seed criteria updated successfully.', 'Success');
      } else if (response.data.statusCode === 404) {
        this.closeModal();
        this.$alert(response.data.message , 'Error');
        this.$router.push({ name: 'Teams' }); 
      } else {
        this.closeModal();
        this.$alert('Failed to update seed criteria. Please try again later.', 'Error');
      }
    } catch (error) {
      console.error("Error saving selected Seedars:", error);
      this.$alert('An unexpected error occurred. Please try again later.', 'Error');
    } finally {
      this.$set(this.isLoadingArray, index, false); 
    }
  },

    handleAvailableSeedars(evt) {
      if (evt.added) {
        this.selectedSeedars.push(evt.added.element);
        console.log(this.selectedSeedars);
      }
    },
    handleSelectedSeedars(evt) {
      if (evt.removed) {
        const index = this.selectedSeedars.indexOf(evt.removed.element);
        console.log(index);
        if (index > -1) {
          this.selectedSeedars.splice(index, 1);
        }
      }
    },

    async getSeedarsForAssociation() {
      try {
        this.showLoaderSelectedSeedars = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getEventSelectedSeedars`,
          {
            params: {
              event_id: this.eventId,
              association_id: this.association_id, // Pass association_id here
            }
          }
        );

        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          this.selectedSeedars = Object.values(response.data.data);
          this.showLoaderSelectedSeedars = 0;
        } else if (response.data.statusCode === 404) {
          this.closeModal();
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: 'Teams' });
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderSelectedSeedars = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderSelectedSeedars = 0;
      }
    },
    async getEventSeedars() {
      try {
      console.log(this.association_id);
        this.showLoaderAvailableSeeadrs = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/fetchFilteredSeedars`,
          {
            params: {
              event_id: this.eventId,
              association_id: this.association_id, // Pass association_id here
            }
          }
        );

        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          this.availableSeedars = Object.values(response.data.data);
          this.showLoaderAvailableSeeadrs = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderAvailableSeeadrs = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderAvailableSeeadrs = 0;
      }
    },
  },

  mounted() {
    this.$root.$on("openManageEventSeedarsModal", () => {
      console.log(this.eventId);
      this.getEventSeedars();
      this.getSeedarsForAssociation();
      var elem = this.$refs.openModalManageSeedars;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
