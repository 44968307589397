<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditTournament"
      ref="openModalEditTournament"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="EditTournament">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Update Division</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalAddTournament"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="name">Division Name</label>
              <input
                id="name"
                class="form-control"
                v-model="tournament_name"
                spellcheck="false"
                autocomplete="off"
                trim
                type="text"
                required
                @input="errorNull"
              />
            </div>
            <div class="row no-gutters">
              <div class="col-sm-12 col-md-6 pr-0 pr-md-2">
                <div class="form-group">
                  <label for="startDateCheck">Start Date</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="startDateError != ''"
                  >
                    Please select Start Date.
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="eventStartDateError != ''"
                  >
                  Start date is outside the event date range.
                  </span>
                  <date-picker
                    :open="open"
                    @close="open = false"
                    :editable="false"
                    :clearable="false"
                    @click.native="handleClickStart"
                    v-model="startDateForField"
                    valueType="format"
                    format="YYYY-MM-DD"
                    placeholder="yyyy-mm-dd"
                    @change="checkdate"
                  >
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                <div class="form-group">
                  <label for="endDateCheck">End Date</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="endDateError != ''"
                  >
                    Please select End Date.
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="endDateErrorGreater != ''"
                  >
                    End Date should be greater than start date
                  </span>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="eventEndDateError != ''"
                  >
                  End date is outside the event date range.
                  </span>
                  <date-picker
                    :open="openEnd"
                    @close="openEnd = false"
                    :editable="false"
                    :clearable="false"
                    @click.native="handleClickEnd"
                    v-model="endDateForField"
                    valueType="format"
                    format="YYYY-MM-DD"
                    placeholder="yyyy-mm-dd"
                    @change="checkdate"
                  >
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
            </div>
             <div class="col-12 p-0 mt-2 mb-3">
                <div class="form-check">
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="defaultCheck1" 
                    v-model="isChecked"
                  />
                  <label class="form-check-label" for="defaultCheck1" style="margin-top: 2px;">
                    Add specific age & division
                  </label>
                </div>
              </div>

              <!-- The row will only be displayed when the checkbox is checked -->
              <div class="row no-gutters my-3" v-if="isChecked">
                <div class="col-12 col-md-6 pr-md-2">
                  <label for="age">Select Age</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="showErrors && !selectedAge"
                  >
                    Please select age.
                  </span>
                  <select class="cust-select" v-model="selectedAge">
                    <option disabled value="">Select age</option>
                    <option v-for="option in ageOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
                  </select>
                </div>
                <div class="col-12 col-md-6">
                  <label for="division">Select Division</label>
                  <span
                    class="label-type error event-error ml-2"
                    v-if="showErrors && !selectedDivision"
                  >
                    Please select division.
                  </span>
                  <select class="cust-select" v-model="selectedDivision">
                    <option disabled value="">Select divison</option>
                    <option v-for="option in ratingOptions" :value="option.id" :key="option.id">{{ option.rate }}</option>
                  </select>
                </div>
              </div>
            <div class="col-12 p-0">
              <div class="form-group">
                <label for="tournamentFormat">Division Format</label>
                <textarea
                  type="text"
                  class="form-control pt-3 mt-2 add-notes"
                  id="tournamentFormat"
                  rows="4"
                  v-model="tournamentFormat"
                  @keyup="errorNull"
                  required
                ></textarea>
              </div>
            </div>

            <div class="col-12 p-0">
              <div class="form-group">
                  <label for="tournamentNotes">Notes</label>
                  <textarea
                    type="text"
                    class="form-control pt-3 mt-2 add-notes"
                    id="tournamentNotes"
                    rows="4"
                    v-model="tournamentNotes"
                    @keyup="errorNull"
                    required
                  ></textarea>
                </div>
            </div>

            <!-- <div class="col-12 p-0">
              <div>
                <label for="summernote">Tournament Format</label>
                <vue-editor v-model="tournamentFormat" :editorToolbar="customToolbar"></vue-editor>
              </div>
           </div> -->

          </div>
          <div class="event-btns-wrapper p-3 justify-content-between">
            <button
              type="button"
              class="btn btn-danger delte-btn"
              block
              @click="deleteTournament(0)"
              :disabled="isDeleteButtonDisabled || isLoadingArrayDelete[0]"
            >
              <div v-if="isLoadingArrayDelete[0]" class="lds-dual-ring"></div>
              <span v-else class="">
                <img src="../../../assets/delete-icon.png">
                Delete</span>
            </button>
            <button
              v-if="!isDisabled"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="editTournament(0)"
              :disabled="isUpdateButtonDisabled || isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isUpdateButtonDisabled || isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { VueEditor, Quill } from 'vue2-editor';

// function getFontName(font) {
//     return font.toLowerCase().replace(/\s/g, "-");
// }

// // Specify Quill fonts
// const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana', 'sans-serif', 'Serif', 'Monospace'];
// const fontNames = fontList.map(font => getFontName(font));
// const fonts = Quill.import('formats/font');
// fonts.whitelist = fontNames;
// Quill.register(fonts, true);

// // Add fonts to CSS style
// let fontStyles = "";
// fontList.forEach(function(font) {
//     let fontName = getFontName(font);
//     fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
//         "content: '" + font + "';" +
//         "font-family: '" + font + "', sans-serif;" +
//         "}" +
//         ".ql-font-" + fontName + "{" +
//         " font-family: '" + font + "', sans-serif;" +
//         "}";
// });
// const node = document.createElement('style');
// node.innerHTML = fontStyles;
// document.body.appendChild(node);

export default {
  name: "EditTournament",
  components: {
    // VueEditor
  },
  data() {
    return {
      open: false,
      openEnd: false,
      startDate: "",
      endDate: "",
      startDateForField: "",
      endDateForField: "",
      exactStartDate: "",
      exactEndDate: "",
      startDateError: "",
      tournament_name: "",
      tournamentFormat: "",
      tournamentNotes: "",
      isChecked: false,
      endDateError: "",
      endDateErrorGreater: "",
      eventStartDateError: "",
      eventEndDateError: "",
      exactStartDateformat: "",
      exactEndDateformat: "",
      isDisabled: true,
      isLoadingArray: [],
      isUpdateButtonDisabled: false,
      isDeleteButtonDisabled: false,
      isLoadingArrayDelete: [],
      selectedTournament: null,
      events_id: "",
      event_guid: "",
      // customToolbar: [
      //   ["bold", "italic", "underline", "strike"],
      //   [{ list: "ordered" }, { list: "bullet" }],
      //   [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      //   [{ font: fonts.whitelist }],
      //   // [{ font: [] }],
      //   // [{ size: ["small", false, "large", "huge"] }],
      //   [{ align: [] }],
      //   [{ color: [] }, { background: [] }],
      //   // ["link", "image", "video"],
      //   // ["code-block"],
      //   // ["clean"]
      // ],
      selectedAge: '', // Holds the selected age
      selectedDivision: '', // Holds the selected division
      showErrors: false,
    };
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
    eventExactStartDate: {
      type: String,
    },
    eventExactEndDate: {
      type: String,
    },
    ratingOptions: {
      type: Array,
    },
    ageOptions: {
      type: Array,
    },
  },
  watch: {
    startDateForField(newVal) {
      this.startDate = newVal;
      this.checkdate();
    },
    endDateForField(newVal) {
      this.endDate = newVal;
      this.checkdate();
    },
    tournamentFormat(newVal) {
      // Check if the new value contains any HTML tags
      const tagRegex = /<[^>]*>/g;
      if (tagRegex.test(newVal)) {
        // Create a temporary div to strip the tags
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = newVal;
        // Assign the plain text back to tournamentFormat
        this.tournamentFormat = tempDiv.textContent || tempDiv.innerText || "";
      }
      // Call errorNull logic
      this.errorNull();
    },
    tournament_name: "errorNull",
    isChecked(newValue) {
      // If the checkbox is unchecked, hide the errors
      if (!newValue) {
        this.selectedAge = "";
        this.selectedDivision = "";
        this.showErrors = false;
      }
    },
  },
  methods: {
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    handleClickEnd(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.openEnd = true;
      }
    },
    deleteTournament(index) {
    //   if(this.selectedTournament){
    //     console.log(this.selectedTournament)
    //   return;
    // }
      this.isUpdateButtonDisabled = true;
      this.isDeleteButtonDisabled = true;
      this.$set(this.isLoadingArrayDelete, index, true);

      const formData = {
        event_id: this.eventId ? this.eventId : this.events_id,
        tournament_id: this.selectedTournament.id,
        association_id: this.association_id,
      };

      axios.post("/associationEvent/deletedTournament", formData)
        .then((response) => {
          console.log(response);
          if (response.data.statusCode === 200) {
            this.$alert(response.data.message);
            const currentUrl = window.location.href;
            if (currentUrl.includes("tournament")) {
              this.$root.$emit("fetchTournaments1", this.event_guid);
            } else {
                this.$root.$emit("fetchTournaments");
            }
            this.$refs.closeModalAddTournament.click();
            // Parse the current URL to get the tournament ID
            var url = window.location.pathname;

            if (url.includes("tournament")) {
              this.$router.push({ name: 'AssociationSingleEventDetail', params: { id: this.selectedTournament?.event?.guid } });
            }

          }else if(response.data.statusCode === 404){
            this.$alert(response.data.message);
            this.$router.push({ name: "Teams" });
            this.$refs.closeModalAddTournament.click();
          }
        })
        .catch((error) => {
          console.log(error);
          // Handle the error and alert the incoming API message
          if (error.response && error.response.data && error.response.data.message) {
            this.$alert(error.response.data.message);
          } else {
            this.$alert('An error occurred while deleting the tournament.');
          }
        })
        .finally(() => {
          // Always reset the loading state and button states
          this.$set(this.isLoadingArrayDelete, index, false);
          this.isUpdateButtonDisabled = false;
          this.isDeleteButtonDisabled = false;
        });
    },
    editTournament(index) {
       if (this.isChecked) {
          if ( !this.selectedAge || !this.selectedDivision) {
            this.showErrors = true;  // Show validation errors
          }
      
          else {
            const formData = {
              event_id: this.eventId ? this.eventId : this.events_id,
              tournamentName: this.tournament_name,
              format: this.tournamentFormat,
              notes: this.tournamentNotes,
              tournament_id: this.selectedTournament.id,
              startDateForField: this.startDate,
              endDateForField: this.endDateForField,
              startDate: this.exactStartDateformat,
              endDate: this.exactEndDateformat,
              exactStartDate: this.exactStartDate,
              exactEndDate: this.exactEndDate,
              association_id: this.association_id,
              rate_id: this.selectedDivision,
              age_group: this.selectedAge,
            };
            this.$set(this.isLoadingArray, index, true);
            this.isUpdateButtonDisabled = true;
            this.isDeleteButtonDisabled = true;
            axios.post("/associationEvent/updatedTournament", formData)
              .then((response) => {
                // Handle response based on statusCode
                if (response.data.statusCode === 200) {
                  // Success scenario
                  const currentUrl = window.location.href;
                  console.log(currentUrl);

                  if (currentUrl.includes("tournament")) {
                    console.log("currentUrl", true);

                    this.$root.$emit("fetchTournaments1", this.event_guid);
                  } else {
                      this.$root.$emit("fetchTournaments");
                  }                  this.$refs.closeModalAddTournament.click();
                  this.dataReset();
                } else if (response.data.statusCode === 404) {
                  this.$refs.closeModalAddTournament.click();
                  this.$alert(response.data.message, 'Error');
                  this.$router.push({ name: "Teams" });
                } else {
                  // Other error scenarios
                  console.error(response.data.message || "Unknown error occurred");
                }
              })
              .catch((error) => {
                // Handle network or other errors
                console.error(error);
              })
              .finally(() => {
                this.$set(this.isLoadingArray, index, false);
                this.isUpdateButtonDisabled = false;
                this.isDeleteButtonDisabled = false;
              });
          }
       }
        else {
            const formData = {
              event_id: this.eventId ? this.eventId : this.events_id,
              tournamentName: this.tournament_name,
              format: this.tournamentFormat,
              notes: this.tournamentNotes,
              tournament_id: this.selectedTournament.id,
              startDateForField: this.startDate,
              endDateForField: this.endDateForField,
              startDate: this.exactStartDateformat,
              endDate: this.exactEndDateformat,
              exactStartDate: this.exactStartDate,
              exactEndDate: this.exactEndDate,
              association_id: this.association_id,
              rate_id: 0,
              age_group: "",
            };
            this.$set(this.isLoadingArray, index, true);
            this.isUpdateButtonDisabled = true;
            this.isDeleteButtonDisabled = true;
            axios.post("/associationEvent/updatedTournament", formData)
              .then((response) => {
                // Handle response based on statusCode
                if (response.data.statusCode === 200) {
                  // Success scenario
                  console.log(response);
                  console.log(formData.event_id);
                  const currentUrl = window.location.href;
                  console.log(currentUrl);

                  if (currentUrl.includes("tournament")) {
                    console.log("currentUrl", true);

                    this.$root.$emit("fetchTournaments1", this.event_guid);
                  } else {
                      this.$root.$emit("fetchTournaments");
                  }
                  this.$refs.closeModalAddTournament.click();
                  this.dataReset();
                } else if (response.data.statusCode === 404) {
                  this.$refs.closeModalAddTournament.click();
                  this.$alert(response.data.message, 'Error');
                  this.$router.push({ name: "Teams" });
                } else {
                  // Other error scenarios
                  console.error(response.data.message || "Unknown error occurred");
                }
              })
              .catch((error) => {
                // Handle network or other errors
                console.error(error);
              })
              .finally(() => {
                this.$set(this.isLoadingArray, index, false);
                this.isUpdateButtonDisabled = false;
                this.isDeleteButtonDisabled = false;
              });
          }
    },
    errorNull() {
      if ( !this.tournament_name || !this.startDateForField || !this.endDateForField || !this.tournamentFormat || this.endDateErrorGreater!== "" || this.eventStartDateError || this.eventEndDateError) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    checkdate: function() {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // Event exact start and end date
  const eventExactStart = new Date(this.eventExactStartDate);
  const eventExactEnd = new Date(this.eventExactEndDate);

      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear;
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate =
          month[startMonth - 1] + " " + startDay + ", " + startYear;

          // Validation: Start date must be within eventExactStart and eventExactEnd
    if (startDateConvert < eventExactStart || startDateConvert > eventExactEnd) {
      this.eventStartDateError = 'on';
    }
    else {
      this.eventStartDateError = "";
    }

      }

      // End Date-----
      if (this.endDate.length == 10) {
        const endDateArray = this.endDate.split("-");
        var endMonth = endDateArray[1];
        var endDay = endDateArray[2];
        var endYear = endDateArray[0];

        var varDatee = endMonth + "-" + endDay + "-" + endYear;
        var endDateConvert = new Date(varDatee);
        
        // Validation: End date must be within eventExactStart and eventExactEnd
    if (endDateConvert < eventExactStart || endDateConvert > eventExactEnd) {
      this.eventEndDateError = 'End date is outside the event range.';
    }
    else {
      this.eventEndDateError = "";
    }

        if (endDateConvert < startDateConvert && this.eventEndDateError == "" && this.eventStartDateError == "") {
          this.endDateError = "";
          this.endDateErrorGreater = "on";
          this.exactEndDate = "";
          this.isDisabledEvent = true;
        } else {
          this.exactEndDateformat = endDateConvert;
          this.endDateError = "";
          this.endDateErrorGreater = "";
          this.exactEndDate =
            month[endMonth - 1] + " " + endDay + ", " + endYear;
        }
      }

      this.checkTimeDate();
    },

   
    formatDateForField(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    checkTimeDate() {
      if (!this.exactStartDate && this.exactEndDate) {
        this.startDateError = "on";
      } else {
        this.startDateError = "";
      }

      if (!this.exactEndDate && this.exactStartDate) {
        this.endDateError = "on";
      } else {
        this.endDateError = "";
      }

      // if (this.exactStartDate && this.exactEndDate) {
        this.errorNull();
      // }
    },
    dataReset() {
      this.startDate = "";
      this.endDate = "";
      this.startDateForField = "";
      this.endDateForField = "";
      this.exactStartDate = "";
      this.exactEndDate = "";
      this.startDateError = "";
      this.eventStartDateError = "";
      this.eventEndDateError = "";
      this.endDateError = "";
      this.endDateErrorGreater = "";
      this.isDisabled = true;
      this.showErrors = false;
    },
  },
  
  mounted() {
    this.$root.$on("openTournamentEditModal", (tournament) => {
      console.log("1", tournament)
      this.selectedTournament = tournament;
      this.events_id = tournament.event_id;
      this.event_guid = tournament?.event?.guid;
      this.tournament_name = tournament.tournamentName;
      this.tournamentFormat = tournament.format;
      this.tournamentNotes = tournament.notes;
      this.selectedAge = tournament.age_group;
      this.selectedDivision = tournament.rate_id;
      if(this.selectedAge && this.selectedDivision){
        this.isChecked = true
      } else{
        this.isChecked = false
      }
      this.startDate = tournament.startDateForField;
      this.endDate = tournament.endDateForField;
      this.exactStartDateformat = tournament.startDate;
      this.exactEndDateformat = tournament.endDate;
      this.startDateForField = tournament.startDateForField;
      this.endDateForField = tournament.endDateForField;
      this.exactStartDate = tournament.exactStartDate;
      this.exactEndDate = tournament.exactEndDate;
      var elem = this.$refs.openModalEditTournament;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style scoped>
.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}

.btn-danger, .event-btns-wrapper .btn-danger {
    background-color: #FFDEE0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #dc3545;
    min-width: 90px;
}
.event-btns-wrapper .btn-danger img {
    width: 16px;
    filter: brightness(10);
    -webkit-filter: none;
    margin-right: 5px;
}
</style>