<template>
  <div>
    <AddMessage :messageTopics="messageTopics" />
    <div class="d-flex justify-content-between" v-if="currentIsAdmin == '1' || currentIsUmpire == '1'">
      <select class="form-control col-6 col-sm-4 col-md-2" v-model="topic" @change="handleChange">
        <option value="" selected>Select Topic</option>
        <option
          v-for="topic in messageTopics"
          :key="topic.id"
          :value="topic.id"
          >{{ topic.name }}</option
        >
      </select>
      <button class="btn btn-primary" @click="messageModal()" >
        New Message
      </button>
      <!-- <router-link to="/message-board" class="m-0">Message</router-link> -->
    </div>
    <b-row class="no-gutters" v-if="messageBodyData.length > 0">
      <div class="col-12 products-column">
        <div class="event-card">
          <div class="table-responsive mb-0">
            <b-table
              :fields="fields"
              :items="messageBodyData"
              hover
              responsive
              @row-clicked="handleRowClick"
              class="mt-3 b-datatable cursor-pointer"
            >
              <template v-slot:cell(action)="data">
                <div
                  class="d-md-flex align-items-center justify-content-between my-2"
                >
                  <div class="icons-side">
                    <div class="back-square">
                      <img src="../../assets/images/back-square.png" alt="" />
                      <span>{{ data.item.replies_count }}</span>
                    </div>
                    <div class="back-square">
                      <img src="../../assets/images/message-eye.png" alt="" />
                      <span>{{ data.item.views_count }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:cell(message_subject)="data">
               
                  <span class="message-btn">{{ data.value }}</span>
              
              </template>
              <template v-slot:cell(message_body)="data">
               
               <span class="message-content-infos">{{ data.value }}</span>
           
           </template>
            </b-table>
            <content-placeholders :rounded="true" v-if="showLoaderMessage">
              <content-placeholders-heading :img="false" />
            </content-placeholders>
          </div>
        </div>
      </div>
    </b-row>
    <div
      v-if="messageBodyData.length"
      v-observe-visibility="handleScrolledToButtom"
    ></div>

    <div
      class="no-products-in-shop"
      v-if="
        (!messageBodyData || messageBodyData.length === 0) && showLoaderMessage === 0
      "
    >
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No message found.</p>
      <p class="info">
        There are no message to display.
      </p>
    </div>

    <div
      class="products-list-shim p-0 mt-3"
      v-if="showLoaderMessage == 1"
    >
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import AddMessage from "./Modals/AddMessage.vue";
export default {
  props: {
    messageBodyData: {
      type: Array,
      required: true
    },
    messageTopics: {
      type: Array,
      required: true
    },
    eventId: {
      type: Number,
      required: true,
    },
    association_id: {
      type: Number,
    },
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    msgLastPage: {
      type: Number,
    },
    messagepage: {
      type: Number,
    },
    showLoaderMessage:{
      type: Number
    }
  },
  name: "AssociationMessageBoard",
  components: {
    AddMessage,
  },
  data() {
    return {
      topic: "",
      // msgLastPage: 1,
      // page: 1,
      // showLoader1: 1,
      // showLoader: 1,
      // messageBodyData: [],
      // messageTopics: [],
      messageBoardListenerAdded: false,
      fields: [
        // {
        //   key: "message_date",
        //   label: "",
        //   formatter: (value) => {
        //     if (!value) {
        //       return "N/A";
        //     } else {
        //       // Extract date and time parts from the datetime string
        //       const [datePart, timePart] = value.split("T");
        //       const [year, month, day] = datePart.split("-");
        //       const [hour, minute] = timePart.split(":");

        //       const months = [
        //         "January",
        //         "February",
        //         "March",
        //         "April",
        //         "May",
        //         "June",
        //         "July",
        //         "August",
        //         "September",
        //         "October",
        //         "November",
        //         "December",
        //       ];
        //       const monthIndex = parseInt(month) - 1;
        //       const formattedDate = `${months[monthIndex]} ${parseInt(day)}, ${year}`;

        //       // Format time as HH:MM AM/PM
        //       const hours = parseInt(hour);
        //       const minutes = parseInt(minute);
        //       const period = hours >= 12 ? "PM" : "AM";
        //       const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
        //       const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;

        //       return `${formattedDate} at ${formattedTime}`;
        //     }
        //   },
        // },

        {
          key: "message_date",
          label: "",
          formatter: (value) => {
            if (!value) {
              return "N/A";
            } else {
              const utcDate = new Date(value); // Parse the UTC datetime
              const localDate = utcDate.toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
              return localDate;
            }
          },
        },

        { key: "message_subject", label: "" },
        { key: "message_body", label: "" },
        { key: "action", label: "", class: "text-center" },
      ],
    };
  },
  methods: {
    handleScrolledToButtom(isVisible) {
      if (this.messagepage >= this.msgLastPage) {
        return;
      }
      if (isVisible) {

        this.$root.$emit("getMessageBoards"); // Emit event to notify parent component
      }
    },
    messageModal() {
        this.$root.$emit("addMessageModal", { 
        eventId: this.eventId, 
        association_id: this.association_id 
      });  
    },
    handleChange() {
      this.$root.$emit("fetchMesagesForTopics", this.topic);

    },
    handleRowClick(item) {
      if (this.currentIsAdmin === '1' || this.currentIsUmpire === '1') {
        this.$router.push({
          name: "MessagesBoard",
          params: { messageId: item.guid },
        });
      }
    },
  },
  mounted() {
    if (!this.messageBoardListenerAdded) {
      
      this.messageBoardListenerAdded = true;
    }
  
  },
};
</script>